import {type Result, type AppError} from '@packages/utils';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {
  type LogoutResponse,
  LogoutResponseSchema,
} from '../entities/logout/schema';
import {sendApiRequest} from '../usecases/auth/sendApiRequest';

export type ResultLogout = Result<LogoutResponse, AppError>;

export type ILogout = {
  cookies: Partial<Record<string, string>>;
  version?: string;
};

export const logout = async ({
  cookies,
  version = DEFAULT_API_VERSION,
}: ILogout): Promise<ResultLogout> => {
  const request = async (accessToken: string) => {
    const path = `/${version}/users/signout`;
    defaultClient.setToken(accessToken);

    return defaultClient.post(path, LogoutResponseSchema, {});
  };

  const result = await sendApiRequest({
    cookies,
    request,
  });

  return result;
};
