import {useCallback, useState} from 'react';
import {useRouter} from 'next/router';
import {parseCookies} from 'nookies';
import {
  type UsersMeResponse,
  useUsersMe,
} from '../repositories/usersMeRepository';
import {HOME} from '../../config/constants/routes';
import {logout} from '../repositories/logoutRepository';
import {validateCookie} from './auth/checkOauthTokenExistence';
import {destroyCookieWithLogout} from './auth/destroyCookieWithLogout';

export type UserType = UsersMeResponse;

export const useAuth = () => {
  const cookies = parseCookies();
  const {isValid, accessToken} = validateCookie(cookies);

  const [user, setUser] = useState<UserType | undefined>(undefined);
  const [isIdle, setIsIdle] = useState(isValid);
  const router = useRouter();

  useUsersMe({
    config: {
      enabled: isValid,
      onSuccess(data) {
        if (data.ok) {
          setUser(data.value);
          setIsIdle(false);
        }
      },
      onError() {
        setIsIdle(false);
      },
    },
  });

  const signOut = useCallback(async () => {
    const cookies = parseCookies();
    await logout({cookies});
    destroyCookieWithLogout();

    if (router.pathname === HOME) {
      router.reload(); // トップページでログアウトした場合表示が更新されないためreloadする
      return;
    }

    await router.push(HOME);
  }, []);

  return {
    user,
    accessToken,
    signOut,
    isIdle,
    isSignedIn: !isIdle && Boolean(user),
  };
};
