import {useRouter} from 'next/router';
import {routes} from '../../config/constants';

/**
 * メール認証ページへの遷移元パスをクエリパラメータに追加
 */
export const getMailAuthLinkWithParamsHooks = (
  parameters: Record<string, string>,
) => {
  const router = useRouter();
  const paramsWithInflowPath: Record<string, string> = {
    ...parameters,
  };
  if (router.query.inflowPath && router.asPath.includes('login')) {
    // 元のin_flow_pathを引き継ぐ
    paramsWithInflowPath.inflowPath = String(router.query.inflowPath);
  } else {
    // InflowPathを上書き
    paramsWithInflowPath.inflowPath = router.asPath
      .replace(/\?.*$/, '')
      .replace(/#.*$/, '');
  }

  return `${routes.MAIL_AUTH}?${new URLSearchParams(paramsWithInflowPath).toString()}`;
};
