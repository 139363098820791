import axios from 'axios';
import {type Result, type AppError, InvalidTokenError} from '@packages/utils';
import {type z} from 'zod';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {UpdateAccessTokenResponse} from '../entities/session/schema';
import {type ResultLogin} from './loginRepository';

export type UpdateAccessTokenResponse = z.infer<
  typeof UpdateAccessTokenResponse
>;
export type UpdateAccessTokenValueType = Result<
  UpdateAccessTokenResponse,
  AppError
>;

export const refreshAccesstoken = async ({
  refreshToken,
  version = DEFAULT_API_VERSION,
}: {
  refreshToken?: string;
  version?: string;
}): Promise<ResultLogin> => {
  const result = await defaultClient.put(
    `/${version}/users/refresh-access-token`,
    UpdateAccessTokenResponse,
    {
      refreshToken,
    },
  );

  // エラーの場合は再ログインを促すため、エラーをthrowしない
  if (!result?.ok) {
    return {
      ok: false,
      error: InvalidTokenError,
    };
  }

  return result;
};
